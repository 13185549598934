import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  notFound: {

    [theme.breakpoints.up('xs')]: {
      marginTop: '25%',
      textAlign: 'center',
      minHeight: '80vh',
    },
    [theme.breakpoints.up('sm')]: {

    },
    [theme.breakpoints.up('md')]: {
      marginTop: '20%',
      textAlign: 'center',
      minHeight: '80vh',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '15%',
      textAlign: 'center',
      minHeight: '70vh',
    },
  }
})
)

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const classes = useStyles();

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <div className={classes.notFound}>
        <h1>Not Found</h1>
        <p>
          You just hit a route that doesn&#39;t exist... the sadness. Check out the{' '}
          <Link to="/articles/">blog</Link> instead.
    </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
